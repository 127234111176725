function fetchAndInsertBanners(type, id) {
    fetch(`/api/banners/${type}/${id}`)
            .then(response => response.json())
            .then(data => {
                if (data.banners.length > 0) {
                    const bannerElements = data.banners.map(banner => createBannerElement(banner));

                    const contentDiv = document.querySelector('.js-content');
                    const paragraphs = Array.from(contentDiv.children).filter(el => el.tagName === 'P');

                    if (paragraphs.length > 0) {
                        paragraphs[0].insertAdjacentElement('afterend', bannerElements[0]);

                        if (bannerElements.length > 1 && paragraphs.length > 1) {
                            paragraphs[paragraphs.length - 1].insertAdjacentElement('beforebegin', bannerElements[1]);
                        }
                    }
                }
            })
            .catch(error => console.error(`Failed to fetch banners: ${error}`));
}

function createBannerElement(banner) {
    const bannerDiv = document.createElement('div');
    bannerDiv.classList.add('banner-custom');

    if (banner.image) {
        const logoDiv = document.createElement('div');
        logoDiv.classList.add('logo');

        const logoImage = document.createElement('img');
        logoImage.src = banner.image;
        logoImage.alt = banner.name;
        logoImage.title = banner.name;
        logoImage.width = 210;
        logoImage.height = 103;
        logoImage.loading = 'lazy';

        if (banner.link) {
            const logoLink = document.createElement('a');
            logoLink.href = banner.link;
            logoLink.rel = 'nofollow';
            logoLink.appendChild(logoImage);

            if (banner.additional_params) {
                if (banner.additional_params.ads_type) {
                    logoLink.dataset.ads_type = banner.additional_params.ads_type;
                }
                if (banner.additional_params.ads_id) {
                    logoLink.dataset.ads_id = banner.additional_params.ads_id;
                }
            }

            logoDiv.appendChild(logoLink);
        } else {
            logoDiv.appendChild(logoImage);
        }

        bannerDiv.appendChild(logoDiv);
    }

    const infoDiv = document.createElement('div');
    infoDiv.classList.add('info');

    const heading = document.createElement('strong');
    heading.classList.add('h3__title');
    heading.innerHTML = banner.name;

    const textParagraph = document.createElement('p');
    textParagraph.textContent = banner.text;

    infoDiv.append(heading, textParagraph);
    bannerDiv.appendChild(infoDiv);

    if (banner.link) {
        const buttonDiv = document.createElement('div');
        buttonDiv.classList.add('button');

        const buttonLink = document.createElement('a');
        buttonLink.classList.add('btn', 'btn_special', 'code_el-link', 'out-link', 'js-open-redirect-link');
        buttonLink.href = banner.link;
        buttonLink.rel = 'nofollow';
        buttonLink.textContent = banner.button;

        if (banner.additional_params) {
            if (banner.additional_params.ads_type) {
                buttonLink.dataset.ads_type = banner.additional_params.ads_type;
            }
            if (banner.additional_params.ads_id) {
                buttonLink.dataset.ads_id = banner.additional_params.ads_id;
            }
        }

        buttonDiv.appendChild(buttonLink);
        bannerDiv.appendChild(buttonDiv);
    }

    return bannerDiv;
}

window.fetchAndInsertBanners = fetchAndInsertBanners;
