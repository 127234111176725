<template>
    <div class="card_section">
        <h2 class="card_section-title">
            <img :src="title_icon" alt="available casinos icon">
            {{ available_games_in_casino_text }}
        </h2>
        <section class="cards">
            <div class="cards_inner">
                <casino-item
                    v-for="card in cards"
                    :key="card.id"
                    :card="card"
                    :like="getCardLike(card.id)"
                    :promo_code_text="promo_code_text"
                    :copy_text="copy_text"
                    :copied_text="copied_text"
                    :details_text="details_text"
                    :visit_site_text="visit_site_text"
                    :analysis_text="analysis_text"
                    :available_games_text="available_games_text"
                    :deposit_methods_text="deposit_methods_text"
                    :min_deposit_text="min_deposit_text"
                    :no_deposit_text="no_deposit_text"
                ></casino-item>
            </div>
            <div
                class="cards_bottom"
                v-if="current_page < last_page"
            >
                <button
                    type="button"
                    class="cards_more load-more"
                    @click="nextPage"
                >
                    <span>{{ more_casinos_text }}</span>
                </button>
            </div>
        </section>
    </div>
</template>

<script>
export default {
    props: {
        initialData: String,
    },
    data() {
        const initialData = JSON.parse(this.initialData);

        let guestUuid = localStorage.getItem('guest_uuid');

        if (!guestUuid) {
            guestUuid = crypto.randomUUID();

            localStorage.setItem('guest_uuid', guestUuid);
        }

        return {
            guestUuid: guestUuid,
            active_cards: [],
            current_page: initialData?.current_page ?? null,
            last_page: initialData?.last_page ?? null,
            casinos_route: initialData?.casinos_route ?? '',
            title_icon: initialData?.title_icon ?? '',
            option_icon: initialData?.option_icon ?? '',
            available_games_in_casino_text: initialData?.available_games_in_casino_text ?? '',
            promo_code_text: initialData?.promo_code_text ?? '',
            copy_text: initialData?.copy_text ?? '',
            copied_text: initialData?.copied_text ?? '',
            details_text: initialData?.details_text ?? '',
            visit_site_text: initialData?.visit_site_text ?? '',
            analysis_text: initialData?.analysis_text ?? '',
            available_games_text: initialData?.available_games_text ?? '',
            deposit_methods_text: initialData?.deposit_methods_text ?? '',
            min_deposit_text: initialData?.min_deposit_text ?? '',
            no_deposit_text: initialData?.no_deposit_text ?? '',
            more_casinos_text: initialData?.more_casinos_text ?? '',
            cards: initialData?.cards ?? [],
            likes: [],
        };
    },
    computed: {
        card_ids() {
            return this.cards.map((card) => card.id);
        },
    },
    mounted() {
        this.getLikes(this.card_ids);
    },
    methods: {
        setCardActive(card_id) {
            if (this.active_cards.includes(card_id)) {
                this.active_cards = this.active_cards.filter((value) => value !== card_id);
            } else {
                this.active_cards.push(card_id);
            }
        },
        getCardLike(card_id) {
            const like = this.likes.find((like) => like.id === card_id);

            return {
                id: like?.id || null,
                is_liked: like?.is_liked || false,
                is_disliked: like?.is_disliked || false,
                likes_count: like?.likes_count ?? null,
                dislikes_count: like?.dislikes_count ?? null,
            };
        },
        nextPage() {
            this.current_page++;

            const params = new URLSearchParams({page: this.current_page});

            const requestOptions = {
                method: 'get',
                credentials: 'same-origin',
                headers: {
                    'Accept': 'application/json',
                    'Content-Type': 'application/json'
                },
            };

            fetch(this.casinos_route + '?' + params, requestOptions)
                .then((response) => response.json())
                .then((response) => {
                    response.data.forEach((card) => this.cards.push(card));
                    this.getLikes(response.data.map((card) => card.id))
                })
                .catch(error => console.log(error.message));
        },
        getLikes(card_ids) {
            fetch('/api/cards/likes', {
                method: 'post',
                credentials: 'same-origin',
                headers: {
                    'Accept': 'application/json',
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({
                    _token: document.querySelector('meta[name="csrf-token"]').getAttribute('content'),
                    guest_uuid: this.guestUuid,
                    card_ids: card_ids,
                })
            })
                .then((response) => response.json())
                .then((response) => {
                    response.data.forEach((like) => this.likes.push(like));
                });
        },
    }
}
</script>
