<template>
    <div class="likes">
        <div class="likes_item" :class="{ active: is_liked }">
            <span class="likes_link" @click="like">
                <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M1.74531 9.23879C1.73532 9.12373 1.74936 9.00785 1.78653 8.8985C1.8237 8.78915 1.88319 8.68872 1.96124 8.60359C2.03928 8.51845 2.13417 8.45047 2.23988 8.40395C2.34559 8.35744 2.45982 8.3334 2.57531 8.33337H4.16656C4.38757 8.33337 4.59954 8.42117 4.75582 8.57745C4.9121 8.73373 4.99989 8.94569 4.99989 9.16671V17.0834C4.99989 17.3044 4.9121 17.5163 4.75582 17.6726C4.59954 17.8289 4.38757 17.9167 4.16656 17.9167H3.26406C3.05549 17.9168 2.85448 17.8386 2.70074 17.6977C2.54699 17.5567 2.45169 17.3632 2.43364 17.1555L1.74531 9.23879V9.23879ZM7.49989 8.90629C7.49989 8.55796 7.71656 8.24629 8.03114 8.09796C8.71823 7.77379 9.88864 7.12254 10.4166 6.24212C11.097 5.10712 11.2253 3.05671 11.2461 2.58712C11.2491 2.52129 11.2474 2.45545 11.2561 2.39045C11.3691 1.5767 12.9395 2.52712 13.5416 3.53212C13.8686 4.07712 13.9103 4.79337 13.8761 5.35296C13.8391 5.95129 13.6636 6.52921 13.4916 7.10337L13.1249 8.32712H17.6486C17.7774 8.32711 17.9044 8.35694 18.0197 8.41426C18.135 8.47158 18.2354 8.55484 18.3131 8.65749C18.3908 8.76015 18.4437 8.87942 18.4676 9.00594C18.4915 9.13246 18.4857 9.26279 18.4507 9.38671L16.2132 17.31C16.1638 17.4848 16.0587 17.6386 15.9138 17.7481C15.769 17.8576 15.5923 17.9168 15.4107 17.9167H8.33323C8.11221 17.9167 7.90025 17.8289 7.74397 17.6726C7.58769 17.5163 7.49989 17.3044 7.49989 17.0834V8.90629Z" stroke="#31325E" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
                </svg>
            </span>
            <span class="likes_count">{{ likes_count ?? '&nbsp;' }}</span>
        </div>
        <div class="likes_item" :class="{ active: is_disliked }">
            <span class="likes_link" @click="dislike">
                <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <g clip-path="url(#clip0_916_11264)">
                        <path d="M18.2547 10.7612C18.2647 10.8763 18.2506 10.9922 18.2135 11.1015C18.1763 11.2108 18.1168 11.3113 18.0388 11.3964C17.9607 11.4815 17.8658 11.5495 17.7601 11.596C17.6544 11.6426 17.5402 11.6666 17.4247 11.6666L15.8334 11.6666C15.6124 11.6666 15.4005 11.5788 15.2442 11.4225C15.0879 11.2663 15.0001 11.0543 15.0001 10.8333L15.0001 2.91663C15.0001 2.69561 15.0879 2.48365 15.2442 2.32737C15.4005 2.17109 15.6124 2.08329 15.8334 2.08329L16.7359 2.08329C16.9445 2.08324 17.1455 2.16141 17.2993 2.30235C17.453 2.44329 17.5483 2.63676 17.5664 2.84454L18.2547 10.7612V10.7612ZM12.5001 11.0937C12.5001 11.442 12.2834 11.7537 11.9689 11.902C11.2818 12.2262 10.1114 12.8775 9.58344 13.7579C8.90302 14.8929 8.77469 16.9433 8.75386 17.4129C8.75094 17.4787 8.75261 17.5445 8.74386 17.6095C8.63094 18.4233 7.06052 17.4729 6.45844 16.4679C6.13136 15.9229 6.08969 15.2066 6.12386 14.647C6.16094 14.0487 6.33636 13.4708 6.50844 12.8966L6.87511 11.6729L2.35136 11.6729C2.2226 11.6729 2.0956 11.6431 1.98031 11.5857C1.86502 11.5284 1.76458 11.4452 1.68687 11.3425C1.60916 11.2398 1.5563 11.1206 1.53242 10.9941C1.50855 10.8675 1.51432 10.7372 1.54927 10.6133L3.78677 2.68996C3.83617 2.51521 3.94129 2.36138 4.08615 2.25187C4.23101 2.14236 4.40768 2.08317 4.58927 2.08329L11.6668 2.08329C11.8878 2.08329 12.0997 2.17109 12.256 2.32737C12.4123 2.48365 12.5001 2.69561 12.5001 2.91663L12.5001 11.0937Z" stroke="#31325E" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
                    </g>
                    <defs>
                        <clipPath>
                            <rect width="20" height="20" fill="white" transform="translate(20 20) rotate(-180)"/>
                        </clipPath>
                    </defs>
                </svg>
            </span>
            <span class="likes_count">{{ dislikes_count ?? '&nbsp;' }}</span>
        </div>
    </div>
</template>

<script>
export default {
    props: {
        cardLike: {
            type: [Object, null],
            default: null,
        },
        cardId: {
            type: [Number, String, null],
            default: null,
        }
    },
    data() {
        let guestUuid = localStorage.getItem('guest_uuid');

        if (!guestUuid) {
            guestUuid = crypto.randomUUID();

            localStorage.setItem('guest_uuid', guestUuid);
        }

        return {
            guestUuid: guestUuid,
            id: this.cardLike?.id || null,
            is_liked: this.cardLike?.is_liked || false,
            is_disliked: this.cardLike?.is_disliked || false,
            likes_count: this.cardLike?.likes_count || null,
            dislikes_count: this.cardLike?.dislikes_count || null,
        };
    },
    watch: {
        cardLike(newVal) {
            this.id = newVal.id;
            this.is_liked = newVal.is_liked;
            this.is_disliked = newVal.is_disliked;
            this.likes_count = newVal.likes_count;
            this.dislikes_count = newVal.dislikes_count;
        },
    },
    mounted() {
        if (!this.cardLike) {
            this.getLike(parseInt(this.cardId));
        }
    },
    methods: {
        getLike(card_id) {
            fetch('/api/cards/likes', {
                method: 'post',
                credentials: 'same-origin',
                headers: {
                    'Accept': 'application/json',
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({
                    _token: document.querySelector('meta[name="csrf-token"]').getAttribute('content'),
                    guest_uuid: this.guestUuid,
                    card_ids: [card_id],
                })
            })
                .then((response) => response.json())
                .then((response) => {
                    this.id = response.data[0].id;
                    this.is_liked = response.data[0].is_liked;
                    this.is_disliked = response.data[0].is_disliked;
                    this.likes_count = response.data[0].likes_count;
                    this.dislikes_count = response.data[0].dislikes_count;
                });
        },
        like() {
            if (!this.is_liked) {
                fetch(`/api/cards/${this.id}/like`, {
                    method: 'post',
                    credentials: 'same-origin',
                    headers: {
                        'Accept': 'application/json',
                        'Content-Type': 'application/json',
                    },
                    body: JSON.stringify({
                        _token: document.querySelector('meta[name="csrf-token"]').getAttribute('content'),
                        guest_uuid: this.guestUuid,
                    }),
                })
                    .then((response) => response.json())
                    .then((response) => {
                        this.is_liked = response.data.is_liked;
                        this.is_disliked = response.data.is_disliked;
                        this.likes_count = response.data.likes_count;
                        this.dislikes_count = response.data.dislikes_count;
                    })
                    .catch((error) => console.log(error.message));
            }
        },
        dislike() {
            if (!this.is_disliked) {
                fetch(`/api/cards/${this.id}/dislike`, {
                    method: 'post',
                    credentials: 'same-origin',
                    headers: {
                        'Accept': 'application/json',
                        'Content-Type': 'application/json',
                    },
                    body: JSON.stringify({
                        _token: document.querySelector('meta[name="csrf-token"]').getAttribute('content'),
                        guest_uuid: this.guestUuid,
                    }),
                })
                    .then((response) => response.json())
                    .then((response) => {
                        this.is_liked = response.data.is_liked;
                        this.is_disliked = response.data.is_disliked;
                        this.likes_count = response.data.likes_count;
                        this.dislikes_count = response.data.dislikes_count;
                    })
                    .catch((error) => console.log(error.message));
            }
        },
    },
}
</script>
