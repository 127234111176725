<template>
    <div
        :class="{
            cards_el: true,
            active: is_active,
        }"
        :style="card.badge ? `border-color: ${card.badge.color};` : ''"
    >
        <div class="cards_badge" :style="`background-color: ${card.badge?.color};`">
            {{ card.badge?.title }}
        </div>
        <div class="cards_main">
            <div class="cards_img">
                <a
                    class="cards_img-inner"
                    :href="card.url"
                >
                    <img
                        loading="lazy"
                        :src="card.logo"
                        :alt="card.title"
                    />
                </a>
            </div>
            <div class="cards_main-info">
                <a
                    class="cards_name"
                    :href="card.url"
                >
                    {{ card.title }}
                </a>
                <div
                    class="cards_star_rating"
                    v-if="card.rating"
                >
                    {{ card.rating }}
                </div>
                <div
                    class="cards_code"
                    v-if="card.settings?.has_promocode"
                >
                    <span class="cards_code-title">{{ promo_code_text }}</span>
                    <div
                        class="cards_code-val"
                        v-if="card.manual_promocode"
                    >
                        {{ card.manual_promocode }}
                    </div>
                    <div
                        class="cards_code-val"
                        v-else-if="card.promocode?.published"
                    >
                        {{ card.promocode?.value }}
                    </div>
                    <div
                        class="cards_code-val"
                        v-else
                    >
                        NO CODE REQUIRED
                    </div>
                    <div class="cards_code-copy">
                        <span>{{ copy_text }}</span>
                        <span>{{ copied_text }}</span>
                    </div>
                </div>
                <like :card-like="cardLike"></like>
            </div>
            <button
                type="button"
                class="cards_el-more"
                @click="is_active = !is_active"
            >
                <span>{{ details_text }}</span>
            </button>
        </div>
        <div class="cards_info">
            <div class="cards_info-inner">
                <div
                    class="cards_details"
                    v-if="(card.bullets ?? []).length"
                >
                    <div class="cards_details-title">{{ card.header }}</div>
                    <ul>
                        <li v-for="bullet in card.bullets ?? []">
                            {{ bullet.title }}
                        </li>
                    </ul>
                </div>
                <div class="cards_links">
                    <a v-if="card.redirect_link"
                       class="cards_btn out-link"
                       :href="card.redirect_link"
                       target="_blank"
                       rel="nofollow"
                    >
                        <span>
                            {{ visit_site_text }}
                        </span>
                    </a>
                    <a v-else
                       class="cards_btn out-link call-visit-site-popup-js"
                       :href="card.url"
                       data-type="ajax"
                       :data-popup-link="card.popup_link"
                    >
                        <span>
                            {{ visit_site_text }}
                        </span>
                    </a>
                    <a
                        class="cards_link"
                        :href="`${card.url}`"
                    >
                        {{ analysis_text }}
                    </a>
                </div>
            </div>
            <div
                class="cards_loc"
                v-if="card.facts?.regulatedby_text_show"
            >
                <div
                    class="cards_loc-flag"
                    v-if="card.facts?.regulatedby_image1"
                >
                    <img :src="card.facts.regulatedby_image1" alt="regulated by"/>
                </div>
                <div
                    class="cards_loc-flag"
                    v-if="card.facts?.regulatedby_image2"
                >
                    <img :src="card.facts.regulatedby_image2" alt="regulated by"/>
                </div>
                <div
                    class="cards_loc-flag"
                    v-if="card.facts?.regulatedby_image3"
                >
                    <img :src="card.facts.regulatedby_image3" alt="regulated by"/>
                </div>
                <div
                    class="cards_loc-flag"
                    v-if="card.facts?.regulatedby_image4"
                >
                    <img :src="card.facts.regulatedby_image4" alt="regulated by"/>
                </div>
                <div
                    class="cards_loc-info"
                    v-if="card.facts.license_text"
                >
                    {{ card.facts.license_text }}
                </div>
            </div>
        </div>
        <div class="cards_content">
            <div class="cards_box cards_box_games">
                <div class="cards_box-title">{{ available_games_text }}</div>
                <ul class="cards_list">
                    <li
                        class="cards_list-el"
                        v-for="gameCategory in card.game_categories"
                        v-bind="{ 'data-ok': card.active_game_categories.find((activeGameCategory) => activeGameCategory.id === gameCategory.id) }"
                    >
                    <span class="cards_list-img">
                        <img
                            loading="lazy"
                            :src="gameCategory.logo"
                            :alt="gameCategory.name"
                        />
                    </span>
                        <span class="cards_list-title">{{ gameCategory.name }}</span>
                    </li>
                </ul>
            </div>
            <div
                class="cards_box"
                v-if="(card.deposit_methods ?? []).length"
            >
                <div class="cards_box-title">{{ deposit_methods_text }}</div>
                <ul class="cards_pay">
                    <li v-for="deposit_method in card.deposit_methods ?? []">
                        <img
                            loading="lazy"
                            :src="deposit_method.icon"
                            :alt="deposit_method.title"
                        />
                    </li>
                </ul>
            </div>
            <div
                class="cards_box"
                v-if="card.facts?.mindepost_text_show ?? false"
            >
                <div class="cards_box-title">{{ min_deposit_text }}</div>
                <div class="cards_deposit">{{
                        card.facts?.min_deposit ? '€' + card.facts?.min_deposit : no_deposit_text
                    }}
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import Like from "./Like.vue";

export default {
    components: {Like},
    props: {
        card: Object,
        like: Object,
        promo_code_text: String,
        copy_text: String,
        copied_text: String,
        details_text: String,
        visit_site_text: String,
        analysis_text: String,
        available_games_text: String,
        deposit_methods_text: String,
        min_deposit_text: String,
        no_deposit_text: String,
    },
    data() {
        return {
            is_active: false,
            regulator: this.card?.regulators.length ? this.card?.regulators[0] : null,
            cardLike: this.like,
        };
    },
    watch: {
        like(newVal) {
            this.cardLike = newVal;
        },
    },
}
</script>
